import React from "react";

import elasticSearch from "../assets/img/elastic.svg";
import statamic from "../assets/img/statamic.svg";
import nodejs from "../assets/img/nodejs.svg";
import laravel from "../assets/img/laravel.svg";
import aws from "../assets/img/aws.svg";
import php from "../assets/img/php.svg";
import javascript from "../assets/img/javascript.svg";
import webpack from "../assets/img/webpack.svg";
import react from "../assets/img/react.svg";
import shopify from "../assets/img/shopify.svg";
import bgImg from "../assets/img/FarbtonSattigung.png";

const Skills = () => {
  return (
    <div
      id="skills"
      className="relative z-40 bg-black px-4 pt-10 lg:pl-20 xl:pl-52 lg:pr-20 xl:pr-40 pb-20 lg:pb-40"
    >
      <div className="relative z-10">
        <h1 className="font-black font-gotham text-4xl md:text-5xl lg:text-6xl text-brand-yellow pb-10 md:pb-20 lg:pb-32">
          Skills
        </h1>

        <div className="space-y-10">
          <div className="flex items-center justify-center md:justify-between space-x-10">
            <div>
              <img className="" src={elasticSearch} alt="" />
            </div>
            <div>
              <img className="" src={statamic} alt="" />
            </div>
          </div>

          <div className="md:block flex items-center justify-center space-x-10 md:space-x-0">
            <div className="md:flex justify-center">
              <img className="" src={nodejs} alt="" />
            </div>
            <div className="md:pl-28">
              <img className="" src={laravel} alt="" />
            </div>
          </div>

          <div className="md:flex justify-between items-end space-x-5 space-y-10 md:space-y-0">
            <div className="md:w-1/2 flex justify-center md:justify-around space-x-10 md:space-x-5">
              <div className="">
                <img className="md:mt-10" src={webpack} alt="" />
              </div>
              <div className="">
                <img className="" src={aws} alt="" />
              </div>
            </div>
            <div className="md:w-1/2 flex items-center md:items-start justify-center md:justify-around space-x-10 md:space-x-5">
              <div className="">
                <img className="md:mb-10" src={php} alt="" />
              </div>
              <div className="">
                <img className="" src={javascript} alt="" />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center md:justify-around space-x-10 md:space-x-0 mt-10">
            <div>
              <img className="" src={react} alt="" />
            </div>
            <div>
              <img className="" src={shopify} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* bg face shape */}
      <img
        className="absolute z-0 left-0 bottom-0 w-full h-full object-cover object-bottom"
        src={bgImg}
        alt=""
      />
    </div>
  );
};

export default Skills;
