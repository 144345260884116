import React from "react";
import porsche from "../assets/img/porsche.svg";
import paysafe from "../assets/img/paysafe.svg";
import aboutyou from "../assets/img/aboutyou.svg";
import hofmeister from "../assets/img/hofmeister.svg";
import bgImg from "../assets/img/ebene.svg";
import plusPlus from "../assets/img/plusPlus.svg";

const Facts = () => {
  return (
    <div id="facts" className="relative bg-black pt-12 lg:pt-24 pb-20 lg:pb-40">
      <div className="relative z-20 px-4 lg:pl-32 xl:pl-52 lg:pr-28">
        <h1 className="font-black font-gotham text-4xl md:text-5xl lg:text-6xl text-brand-yellow pb-8 lg:pb-14">
          Facts
        </h1>
        <p className="max-w-4xl font-avenir text-brand-light-gray md:text-lg lg:text-xl">
          We have over 35 years of programming experience and our customers include medium-sized businesses, SME's and large corporations.
        </p>{" "}
        <br />
        <p className="max-w-4xl font-avenir text-brand-light-gray md:text-lg lg:text-xl">
          We look forward to hearing from you and supporting you in your next project.
        </p>
        <div className="flex items-center justify-end space-x-5 pt-12 lg:pt-24">
          <div className="w-1/2">
            <img src={porsche} alt="" />
          </div>
          <div className="w-1/2">
            <img src={paysafe} alt="" />
          </div>
        </div>
        <div className="flex items-center justify-end space-x-5 pt-12">
          <div className="w-1/2">
            <img className="ml-auto" src={aboutyou} alt="" />
          </div>
          <div className="w-1/2">
            <img className="ml-auto" src={hofmeister} alt="" />
          </div>
        </div>
      </div>

      {/* plus plus */}
      <img
        className="absolute z-10 top-5 lg:top-1/2 lg:-translate-y-1/2 xl:translate-y-0 xl:top-1/3 right-20 md:right-32"
        src={plusPlus}
        alt=""
      />

      {/* bg face shape */}
      <img
        className="absolute z-0 bottom-0 right-0 md:right-32 lg:right-60 scale-90 opacity-60"
        src={bgImg}
        alt=""
      />
    </div>
  );
};

export default Facts;
