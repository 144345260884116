import React from "react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import leftArrow from "../assets/img/iconLeft.svg";
import rightArrow from "../assets/img/iconRight.svg";
import api from "../assets/img/api.svg";
import eCommerce from "../assets/img/eCommerce.svg";
import cms from "../assets/img/cms.svg";
import webApp from "../assets/img/webApp.svg";
import gear from "../assets/img/gear.svg";
import lightbulb from "../assets/img/lightbulb.svg";
import target from "../assets/img/target.svg";
import bezier from "../assets/img/bezier.svg";
import bgImg from "../assets/img/servicesBg.svg";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const slider = [
  {
    id: "0",
    iconOne: `${api}`,
    headingOne: "APIs",
    contentOne:
      "Individual programming of new, as well as implementation of existing APIs of all kinds.",
    iconTwo: `${eCommerce}`,
    headingTwo: "E-Commerce",
    contentTwo:
      "Programming and setup, synchronization and relocation of stores including all assets and plugins.",
  },
  {
    id: "1",
    iconOne: `${cms}`,
    headingOne: "CMS-Implementation",
    contentOne:
      "Creation of own or assistance with content management systems.",
    iconTwo: `${webApp}`,
    headingTwo: "Webapplications",
    contentTwo:
      "Programming web apps and other applications used on the web.",
  },
  {
    id: "2",
    iconOne: `${gear}`,
    headingOne: "Admin & Hosting",
    contentOne:
      "Hosting von Websites und ähnlichem, sowie Übernahme der System-Administration.",
    iconTwo: `${target}`,
    headingTwo: "IT-Consulting",
    contentTwo:
      "Hosting of websites and the like, as well as taking over system administration.",
  },
  {
    id: "3",
    iconOne: `${lightbulb}`,
    headingOne: "Custom solutions",
    contentOne:
      "Nothing off the shelf. The right software programmed only for your needs.",
    iconTwo: `${bezier}`,
    headingTwo: "Webdesign & UI/UX",
    contentTwo:
      "Good programming needs smart design. We make application intuitively understandable.",
  }
];

const Services = () => {
  const navigationNextRef = React.useRef(null);
  const navigationPrevRef = React.useRef(null);

  return (
    <div
      id="services"
      className="relative bg-black overflow-hidden pt-14 pb-10 md:pb-20 lg:pb-28"
    >
      <div className="relative z-10">
        <h1 className="font-black font-gotham text-4xl md:text-5xl lg:text-6xl text-brand-yellow px-4 pb-16 lg:pl-32 xl:pl-52">
          Services
        </h1>

        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={70}
          slidesPerView={1.1}
          breakpoints={{
            640: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 2.3,
            },
          }}
          loop
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: navigationNextRef.current,
            prevEl: navigationPrevRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.params.navigation.prevEl = navigationPrevRef.current;
          }}
          pagination={{
            el: ".custom-pagination",
            clickable: true,
          }}
          className="max-w-7xl mx-auto !overflow-visible"
        >
          {slider.map((slide) => (
            <SwiperSlide key={slide.id}>
              <div>
                <div className="flex items-start bg-white/5 rounded-3xl h-72 space-x-6 p-4 xl:p-8 pb-24">
                  <img
                    className="w-10 lg:w-14 xl:w-auto"
                    src={slide.iconOne}
                    alt=""
                  />
                  <div className="font-gotham text-brand-light-gray pt-4">
                    <h2 className="font-bold text-2xl lg:text-3xl xl:text-4xl">
                      {slide.headingOne}
                    </h2>
                    <p className="xl:text-xl pt-4 xl:pt-6">
                      {slide.contentOne}
                    </p>
                  </div>
                </div>
                <div className="flex items-start bg-white/5 rounded-3xl h-72 translate-x-10 space-x-6 p-4 xl:p-8 pb-24 -mt-14">
                  <img
                    className="w-10 lg:w-14 xl:w-auto"
                    src={slide.iconTwo}
                    alt=""
                  />
                  <div className="font-gotham text-brand-light-gray pt-4">
                    <h2 className="font-bold text-2xl lg:text-3xl xl:text-4xl">
                      {slide.headingTwo}
                    </h2>
                    <p className="xl:text-xl pt-4 xl:pt-6">
                      {slide.contentTwo}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="relative z-50 max-w-5xl mx-auto flex items-center justify-end pt-6 pr-4">
            <div className="cursor-pointer p-2" ref={navigationPrevRef}>
              <img src={leftArrow} alt="" />
            </div>
            <div className="custom-pagination !w-auto space-x-2">
              {slider.length}
            </div>
            <div className="flex items-center font-gotham font-black text-brand-yellow"></div>
            <div className="cursor-pointer p-2" ref={navigationNextRef}>
              <img src={rightArrow} alt="" />
            </div>
          </div>
        </Swiper>
      </div>

      {/* bg shape */}
      <img
        className="absolute z-0 inset-0 w-full h-full object-cover mix-blend-luminosity opacity-70"
        src={bgImg}
        alt=""
      />
    </div>
  );
};

export default Services;
