import React from "react";
import {Link} from "react-router-dom";
import Imprint from "./Imprint";
import Privacy from "./Privacy";

const Footer = () => {
    return (
        <div
            className="bg-black flex flex-col md:flex-row items-center justify-center md:justify-between lg:justify-center font-gotham lg:text-lg text-brand-light-gray space-y-4 md:space-y-0 lg:space-x-20 xl:space-x-32 pt-5 pb-14 px-4">
            <Link
                className="hover:text-brand-yellow transition ease-in-out duration-300"
                to="#"
            >
                <Imprint/>
            </Link>
            <Link
                className="hover:text-brand-yellow transition ease-in-out duration-300"
                to="#"
            >
                Gravity Technologies AG ® 2023
            </Link>
            <Link
                className="hover:text-brand-yellow transition ease-in-out duration-300"
                to="#"
            >
                <Privacy/>
            </Link>
        </div>
    );
};

export default Footer;
